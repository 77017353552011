import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { requestSetNewObservation } from 'utils/requests/ride/rideRequests';
import './styles.css';
type props = {
  rideId: number;
};
type FormData = {
  message: string;
};
export function NewObservation({ rideId }: props) {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    requestSetNewObservation(rideId, formData.message)
      .then(() => {
        Swal.close();
        Swal.fire('Nova observação inserida com sucesso', '', 'success');
      })
      .catch(() => {
        Swal.fire({
          text: 'Algo deu errado',
          icon: 'error',
        });
      });
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <input
        className="input"
        placeholder="observação"
        {...register('message', {
          required: 'Campo obrigatório',
        })}
        type="text"
        name="message"
      />
      <div className="text-start invalid-feedback d-block">
        {errors.message?.message}
      </div>
      <div className="ride-expense-btn-container">
        <button className="btn btn-terciary ride-expense-btn" type="submit">
          Salvar
        </button>
        <button
          className="btn btn-danger ride-expense-btn"
          type="button"
          onClick={() => Swal.close()}
        >
          Cancelar
        </button>
      </div>
    </form>
  );
}
