import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import RideData from './RideData';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';

import {
  requestFindHappeningRideByPassengerId,
  requestFindHappeningRideByDriverId,
  requestFinishRideRide,
} from 'utils/requests/ride/rideRequests';
import { PendingRidesResponse } from 'utils/requests/ride/rideRequests.types';
import { SwalAlertError, SwalAlertSucess } from 'utils/SwalAlert/SwalAlert';
import { SwalAlert } from 'utils/SwalAlert/SwalAlert.types';
import { FINISHED_BY_DRIVER } from '../../utils/constants/ride';

import './styles.css';

type Props = {
  isDriverRide?: boolean;
};

const CurrentRide = ({ isDriverRide }: Props) => {
  const history = useHistory();
  const [ride, setRide] = useState<PendingRidesResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const swalSuccessMessage: SwalAlert = {
    content: 'Corrida finalizada com sucesso',
    title: 'Corrida finalizada',
  };

  const swalErrorMessage: SwalAlert = {
    content: 'Erro ao finalizar corrida',
    title: 'Oops',
  };

  const finishRide = () => {
    Swal.fire({
      title: 'Deseja realmente finalizar a corrida?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Finalizar',
    }).then((result) => {
      if (result.isConfirmed) {
        ride?.ride.id &&
          requestFinishRideRide(ride.ride.id, FINISHED_BY_DRIVER)
            .then(() => {
              SwalAlertSucess(swalSuccessMessage).finally(() => {
                history.push('/myapp/menu/driver/dashboard');
              });
            })
            .catch(() => {
              SwalAlertError(swalErrorMessage);
            });
      }
    });
  };

  useEffect(() => {
    if (isDriverRide) {
      requestFindHappeningRideByDriverId()
        .then((response) => {
          setRide(response.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      requestFindHappeningRideByPassengerId()
        .then((response) => {
          setRide(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isDriverRide]);

  return (
    <div className="base-card p-5 p-md-5 mt-5 current-ride-container">
      <div className="container">
        {isLoading ? (
          <ThreeDotsLoader />
        ) : ride ? (
          <>
            <h1 className="page-title mb-4">Corrida atual</h1>
            {!ride?.car && !ride?.status.length ? (
              <h3 className="page-subtitle">
                Quando sua corrida iniciar, acompanhe aqui o status em tempo
                real.
              </h3>
            ) : (
              ride.car && (
                <div>
                  <div className="current-ride-container ">
                    <RideData raceData={ride} isDriverRide={isDriverRide} />
                  </div>
                  <div className="current-ride-buttons-container">
                    {isDriverRide && (
                      <>
                        <button
                          className="btn btn-success"
                          onClick={() => finishRide()}
                        >
                          FINALIZAR CORRIDA
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )
            )}
          </>
        ) : (
          <h1 className="page-title">Não há corrida no momento</h1>
        )}
      </div>
    </div>
  );
};

export default CurrentRide;
